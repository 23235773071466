import { useEffect, useRef } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import "./Terms.css";

const Terms = (props) => {
  //scroll to top
  const ref = useRef(null);
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  });
  return (
    <div ref={ref}>
      <Header />
      <div className="terms-page container">
        <h1>Მოხმარების პირობები</h1>
        <h2>სარჩევი:</h2>
        <ul className="first-list">
          <li>1. შეთანხმება პირობებზე</li>
          <li>2. ინტელექტუალური საკუთრების უფლებები</li>
          <li>3. მომხმარებლის წარმომადგენლობები</li>
          <li>4. აკრძალული აქტივობები</li>
          <li>5. მომხმარებლის მიერ გენერირებული კონტრიბუციები</li>
          <li>6. წვლილი შეტანილი ლიცენზია</li>
          <li>7. სახელმძღვანელო</li>
          <li>8. სოციალური მედია</li>
          <li>9. წინადადებები</li>
          <li>10. მესამე მხარის ვებგვერდი და შინაარსი</li>
          <li>11. საიტის მენეჯმენტი</li>
          <li>12. კონფიდენციალურობის პოლიტიკა</li>
          <li>13. ვადა და შეწყვეტა</li>
          <li>14. ცვლილებები და შეფერხებები</li>
          <li>15. დავის გადაწყვეტა</li>
          
          <li>16. შეზღუდვა</li>
          <li>17. ანაზღაურება</li>
          <li>18. მომხმარებლის მონაცემები</li>
          <li>19. ელექტრონული კომუნიკაციები, გარიგებები და ხელმოწერები</li>
          <li>20 კალიფორნიის მომხმარებლები და რეზიდენტები</li>
          <li>21. სხვადასხვა</li>
          <li>22. დაგვიკავშირდით</li>
        </ul>
        <ul className="second-list">
          <li>
            <h3>1. შეთანხმება პირობებზე</h3>
            <p>
              პირობებზე შეთანხმება წინამდებარე პირობები წარმოადგენს იურიდიულად
              სავალდებულო შეთანხმებას, რომელიც დადებულია თქვენ შორის, პირადად თუ
              სუბიექტის სახელით თქვენ და ჩვენ, თქვენს წვდომასთან და
              გამოყენებასთან დაკავშირებით. ვებსაიტი, ისევე როგორც ნებისმიერი
              სხვა მედია ფორმა, მედია არხი, მობილური ვებსაიტი ან მობილური
              აპლიკაცია დაკავშირებული, დაკავშირებული ან სხვაგვარად დაკავშირებული
              მასთან (ერთობლივად, „საიტი“). თქვენ ეთანხმებით, რომ საიტზე წვდომით
              თქვენ წაიკითხეთ, გაიგეთ და ეთანხმებით, რომ დაიცავთ ყველა ამ
              გამოყენების წესს. თუ არ ეთანხმებით ყველა ამ გამოყენების წესს,
              მაშინ თქვენ პირდაპირ გეკრძალებათ საიტის გამოყენება და დაუყოვნებლივ
              უნდა შეწყვიტოთ გამოყენება. დამატებითი პირობები ან დოკუმენტები,
              რომლებიც შეიძლება დროდადრო განთავსდეს საიტზე, აქ პირდაპირ არის
              ჩართული მითითებით. ჩვენ ვიტოვებთ უფლებას, ჩვენი შეხედულებისამებრ,
              დროდადრო შევიტანოთ ცვლილებები ან ცვლილებები წინამდებარე
              გამოყენების წესებში. ჩვენ გაფრთხილებთ ნებისმიერი ცვლილების შესახებ
              ამ გამოყენების პირობების „ბოლო განახლების“ თარიღის განახლებით და
              თქვენ უარს იტყვით ყოველგვარ უფლებაზე, მიიღოთ კონკრეტული
              შეტყობინება თითოეული ასეთი ცვლილების შესახებ. გთხოვთ, დარწმუნდეთ,
              რომ შეამოწმეთ მოქმედი პირობები ყოველ ჯერზე, როდესაც იყენებთ ჩვენს
              საიტს, რათა გაიგოთ, რომელი პირობები გამოიყენება. თქვენ
              დაექვემდებარებით და ჩაითვლება, რომ გაეცანით და დაეთანხმებით,
              ნებისმიერი შესწორებული გამოყენების პირობების ცვლილებებს თქვენი
              საიტის განაგრძობით გამოყენების შემდეგ, ასეთი შესწორებული
              გამოყენების პირობების განთავსების თარიღის შემდეგ.
            </p>
            <p>
              საიტზე მოწოდებული ინფორმაცია არ არის გამიზნული ნებისმიერი პირის ან
              სუბიექტის მიერ გავრცელებისთვის ან გამოსაყენებლად ნებისმიერ
              იურისდიქციაში ან ქვეყანაში, სადაც ასეთი გავრცელება ან გამოყენება
              ეწინააღმდეგება კანონს ან რეგულაციას, ან რომელიც დაგვაქვეითებს
              რეგისტრაციის ნებისმიერ მოთხოვნას ამ იურისდიქციისა თუ ქვეყანაში. .
              შესაბამისად, ის პირები, რომლებიც ირჩევენ საიტზე წვდომას სხვა
              ადგილებიდან, ამას აკეთებენ საკუთარი ინიციატივით და არიან მხოლოდ
              პასუხისმგებელი ადგილობრივ კანონებთან შესაბამისობაზე, თუ და
              რამდენადაც გამოიყენება ადგილობრივი კანონები. საიტი არ არის
              მორგებული ინდუსტრიის სპეციფიკური რეგულაციების შესასრულებლად
              (ჯანმრთელობის დაზღვევის პორტაბელურობისა და ანგარიშვალდებულების
              აქტი (HIPAA), ფედერალური ინფორმაციის უსაფრთხოების მენეჯმენტის აქტი
              (FISMA) და ა.შ.), ასე რომ, თუ თქვენი ურთიერთქმედება
              დაექვემდებარება ასეთ კანონებს, თქვენ არ შეგიძლიათ გამოიყენეთ ეს
              საიტი. თქვენ არ შეგიძლიათ გამოიყენოთ საიტი ისე, რომ დაარღვევს
              Gramm-Leach-Bliley Act (GLBA). საიტი განკუთვნილია
              მომხმარებლებისთვის, რომლებიც არიან მინიმუმ 18 წლის. 18 წლამდე
              ასაკის პირებს არ აქვთ უფლება გამოიყენონ ან დარეგისტრირდნენ საიტზე.
            </p>
          </li>
          <li>
            <h3>2. ინტელექტუალური საკუთრების უფლებები</h3>
            <p>
              თუ სხვა რამ არ არის მითითებული, საიტი არის ჩვენი საკუთრების
              საკუთრება და ყველა წყაროს კოდი, მონაცემთა ბაზა, ფუნქციონალობა,
              პროგრამული უზრუნველყოფა, ვებსაიტების დიზაინი, აუდიო, ვიდეო,
              ტექსტი, ფოტოები და გრაფიკა საიტზე (ერთობლივად, „შინაარსი“) და
              მასში შემავალი სასაქონლო ნიშნები, სერვისის ნიშნები და ლოგოები
              ("ნიშნები") ეკუთვნის ან ვაკონტროლებთ ან ლიცენზირებულია ჩვენთვის და
              დაცულია საავტორო და სავაჭრო ნიშნების კანონებით და სხვა
              ინტელექტუალური საკუთრების უფლებებით და არაკეთილსინდისიერი
              კონკურენციის შესახებ შეერთებული შტატების კანონებით. , საავტორო
              უფლებების საერთაშორისო კანონები და საერთაშორისო კონვენციები.
              შიგთავსი და ნიშნები მოწოდებულია საიტზე „როგორც არის“ მხოლოდ თქვენი
              ინფორმაციისა და პირადი გამოყენებისთვის. გარდა იმ შემთხვევებისა,
              რაც პირდაპირ არის გათვალისწინებული ამ გამოყენების პირობებში,
              საიტის არცერთი ნაწილი და არცერთი კონტენტი ან ნიშნები არ შეიძლება
              იყოს კოპირება, რეპროდუცირება, გაერთიანება, ხელახლა გამოქვეყნება,
              ატვირთვა, გამოქვეყნება, საჯაროდ ჩვენება, კოდირება, თარგმნა,
              გადაცემა, გავრცელება, გაყიდვა, ლიცენზირება ან სხვაგვარად
              გამოიყენება ნებისმიერი კომერციული მიზნისთვის, ჩვენი წინასწარი
              წერილობითი ნებართვის გარეშე. იმ პირობით, რომ თქვენ გაქვთ საიტის
              გამოყენების უფლება, მოგეცემათ შეზღუდული ლიცენზია საიტზე წვდომისა
              და გამოყენებისთვის და ჩამოტვირთოთ ან დაბეჭდოთ კონტენტის ნებისმიერი
              ნაწილის ასლი, რომელზედაც თქვენ სათანადოდ მოიპოვეთ წვდომა მხოლოდ
              თქვენი პირადი, არაკომერციული მიზნებისთვის. გამოყენება. ჩვენ
              ვიტოვებთ ყველა უფლებას, რომელიც პირდაპირ არ არის მინიჭებული თქვენ
              საიტზე და საიტზე, შიგთავსსა და ნიშნებზე.
            </p>
          </li>
          <li>
            <h3>3. მომხმარებლის წარმომადგენლობები</h3>
            <p>
              საიტის გამოყენებით, თქვენ წარმოადგენთ და იძლევით გარანტიას, რომ:
              <br/>(1) გაქვთ უფლებაუნარიანობა და თანახმა ხართ დაიცვან წინამდებარე
              გამოყენების პირობები;<br/> (2) თქვენ არ ხართ არასრულწლოვანი იმ
              იურისდიქციაში, სადაც ცხოვრობთ;<br/> (3) თქვენ არ შეხვალთ საიტზე
              ავტომატური ან არაადამიანური საშუალებებით, იქნება ეს ბოტის,
              სკრიპტის ან სხვა გზით;<br/> (4) თქვენ არ გამოიყენებთ საიტს რაიმე
              უკანონო ან არაავტორიზებული მიზნით; <br/> (5) თქვენ მიერ საიტის
              გამოყენება არ დაარღვევს რაიმე მოქმედ კანონს ან რეგულაციას.<br/> თუ
              თქვენ მოგვაწვდით რაიმე ინფორმაციას, რომელიც არ შეესაბამება
              სიმართლეს, არაზუსტს, არ არის აქტუალური ან არასრული, ჩვენ გვაქვს
              უფლება შევაჩეროთ ან შევწყვიტოთ თქვენი ანგარიში და უარი თქვან
              საიტის (ან მის რომელიმე ნაწილზე) ნებისმიერ მიმდინარე ან მომავალ
              გამოყენებაზე.
            </p>
          </li>
          <li>
            <h3>4. აკრძალული აქტივობები</h3>
            <p>
              თქვენ არ შეგიძლიათ შეხვიდეთ ან გამოიყენოთ საიტი სხვა მიზნით, გარდა
              იმისა, რისთვისაც ჩვენ ვებსაიტს ვაძლევთ მისაწვდომს. საიტის
              გამოყენება არ შეიძლება რაიმე კომერციულ მცდელობებთან დაკავშირებით,
              გარდა იმ შემთხვევებისა, რომლებიც სპეციალურად არის მოწონებული ან
              დამტკიცებული ჩვენ მიერ. როგორც საიტის მომხმარებელი, თქვენ თანახმა
              ხართ:<br/> არ მიიღოთ სისტემატიურად მონაცემები ან სხვა შინაარსი
              საიტიდან, რათა შექმნათ ან შეადგინოთ, პირდაპირ ან ირიბად, კოლექცია,
              კომპილაცია, მონაცემთა ბაზა ან დირექტორია ჩვენგან წერილობითი
              ნებართვის გარეშე. მოატყუეთ, მოატყუეთ ან შეცდომაში შეგვიყვანოთ ჩვენ
              და სხვა მომხმარებლები, განსაკუთრებით ნებისმიერი მცდელობისას, რომ
              მიიღოთ ისეთი მგრძნობიარე ანგარიშის ინფორმაცია, როგორიცაა
              მომხმარებლის პაროლები. გვერდის ავლა, გამორთვა ან სხვაგვარად ჩარევა
              საიტის უსაფრთხოებასთან დაკავშირებულ ფუნქციებში, მათ შორის
              ფუნქციები, რომლებიც ხელს უშლის ან ზღუდავს ნებისმიერი კონტენტის
              გამოყენებას ან კოპირებას ან ახორციელებს შეზღუდვებს საიტის და/ან
              მასში შემავალი კონტენტის გამოყენების შემცირების, შელახვის, ან
              წინააღმდეგ შემთხვევაში, ჩვენი აზრით, ზიანს აყენებს ჩვენ და/ან
              საიტს. გამოიყენეთ საიტიდან მიღებული ნებისმიერი ინფორმაცია სხვა
              პირის შევიწროების, შეურაცხყოფის ან ზიანის მიყენების მიზნით.
              არასათანადოდ გამოიყენეთ ჩვენი მხარდაჭერის სერვისები ან წარუდგინეთ
              ყალბი შეტყობინებები არასათანადო მოპყრობის ან გადაცდომის შესახებ.
              გამოიყენეთ საიტი ნებისმიერი მოქმედი კანონებისა და რეგულაციების
              შეუსაბამოდ. ჩაერთეთ საიტის არაავტორიზებული ჩარჩოში ან ბმულზე.
              ატვირთეთ ან გადასცეთ (ან მცდელობა ატვირთოთ ან გადასცეს) ვირუსები,
              ტროას ცხენები ან სხვა მასალა, მათ შორის დიდი ასოების გადაჭარბებული
              გამოყენება და სპამი (განმეორებადი ტექსტის უწყვეტი გამოქვეყნება),
              რომელიც ხელს უშლის რომელიმე მხარის უწყვეტ გამოყენებას და
              სარგებლობას საიტით ან ცვლის, აზიანებს, არღვევს, ცვლის ან ხელს
              უშლის საიტის გამოყენებას, ფუნქციებს, ფუნქციებს, ფუნქციონირებას ან
              შენარჩუნებას. ჩაერთეთ სისტემის ნებისმიერ ავტომატიზირებულ
              გამოყენებაში, როგორიცაა სკრიპტების გამოყენება კომენტარების ან
              შეტყობინებების გასაგზავნად, ან ნებისმიერი მონაცემთა მოპოვების,
              რობოტების ან მონაცემთა შეგროვებისა და ამოღების მსგავსი
              ხელსაწყოების გამოყენებით. წაშალეთ საავტორო ან სხვა საკუთრების
              უფლებების შესახებ შეტყობინება ნებისმიერი კონტენტიდან. სხვა
              მომხმარებლის ან პიროვნების განსახიერების მცდელობა ან სხვა
              მომხმარებლის მომხმარებლის სახელის გამოყენება. ნებისმიერი მასალის
              ატვირთვა ან გადაცემა (ან ატვირთვის ან გადაცემის მცდელობა), რომელიც
              მოქმედებს როგორც პასიური ან აქტიური ინფორმაციის შეგროვების ან
              გადაცემის მექანიზმი, მათ შორის, შეუზღუდავად, მკაფიო გრაფიკული
              გაცვლის ფორმატები („გიფები“), 1×1 პიქსელი, ვებ შეცდომები, ქუქიები.
              , ან სხვა მსგავსი მოწყობილობები (ზოგჯერ მოიხსენიება როგორც
              "spyware" ან "პასიური შეგროვების მექანიზმები" ან "pcms"). ჩაერიეთ,
              ჩაშალეთ ან შექმენით ზედმეტი ტვირთი საიტზე ან საიტთან დაკავშირებულ
              ქსელებსა თუ სერვისებში. შევიწროოთ, გააღიზიანოთ, დააშინოთ ან
              დაემუქროთ ჩვენი რომელიმე თანამშრომელი ან აგენტი, რომელიც
              მონაწილეობს საიტის ნებისმიერი ნაწილის თქვენთვის მოწოდებაში. სცადეთ
              გვერდის ავლით საიტის ნებისმიერი ზომა, რომელიც შექმნილია საიტზე
              წვდომის თავიდან ასაცილებლად ან შეზღუდვის მიზნით, დააკოპირეთ ან
              მოერგეთ საიტის პროგრამულ უზრუნველყოფას, მათ შორის, მაგრამ არ
              შემოიფარგლება მხოლოდ Flash, PHP, HTML, JavaScript ან სხვა კოდით.
              მოქმედი კანონმდებლობით დაშვებული შემთხვევების გარდა, გაშიფრეთ,
              დეკომპილი, დაშალეთ ან შეცვალეთ ინჟინერი ნებისმიერი პროგრამული
              უზრუნველყოფის, რომელიც შეიცავს ან რაიმე სახით წარმოადგენს საიტის
              ნაწილს. გარდა სტანდარტული საძიებო სისტემის ან ინტერნეტ ბრაუზერის
              გამოყენების შედეგისა, გამოიყენეთ, გაუშვით, განავითარეთ ან
              გაავრცელეთ ნებისმიერი ავტომატური სისტემა, მათ შორის შეზღუდვის
              გარეშე, ნებისმიერი ობობა, რობოტი, თაღლითური პროგრამა, სკრაპერი ან
              ოფლაინ მკითხველი, რომელიც წვდება საიტს, ან ნებისმიერი
              არაავტორიზებული სკრიპტის ან სხვა პროგრამული უზრუნველყოფის
              გამოყენება ან გაშვება. გამოიყენეთ შესყიდვის აგენტი ან შესყიდვის
              აგენტი საიტზე შესყიდვების განსახორციელებლად. განახორციელეთ საიტის
              არასანქცირებული გამოყენება, მათ შორის მომხმარებლების მომხმარებლის
              სახელების და/ან ელ.ფოსტის მისამართების შეგროვება ელექტრონული ან
              სხვა საშუალებებით არასასურველი ელფოსტის გაგზავნის მიზნით, ან
              მომხმარებლის ანგარიშების შექმნა ავტომატური საშუალებებით ან ყალბი
              პრეტენზიებით. გამოიყენეთ საიტი, როგორც ნებისმიერი ძალისხმევის
              ნაწილი ჩვენთან კონკურენციაში ან სხვაგვარად გამოიყენეთ საიტი და/ან
              შინაარსი ნებისმიერი შემოსავლის მომტანი მცდელობისთვის ან კომერციული
              საწარმოსთვის.
            </p>
          </li>
          <li>
            <h3>5. მომხმარებლის მიერ გენერირებული კონტრიბუციები</h3>
            <p>
              საიტი არ სთავაზობს მომხმარებლებს შინაარსის გაგზავნას ან
              გამოქვეყნებას. ჩვენ შეგვიძლია მოგაწოდოთ შესაძლებლობა შექმნათ,
              გამოაგზავნოთ, გამოაქვეყნოთ, გამოაქვეყნოთ, გადასცეთ, შეასრულოთ,
              გამოაქვეყნოთ, გაავრცელოთ ან გადასცეთ კონტენტი და მასალები ჩვენთვის
              ან საიტზე, მათ შორის, მაგრამ არ შემოიფარგლება მხოლოდ ტექსტით,
              ნაწერებით, ვიდეოებით, აუდიოებით, ფოტოებით. , გრაფიკა, კომენტარები,
              წინადადებები ან პერსონალური ინფორმაცია ან სხვა მასალა (ერთობლივად,
              „წვლილი“). წვლილის ნახვა შესაძლებელია საიტის სხვა მომხმარებლების
              მიერ და მესამე მხარის ვებსაიტების საშუალებით. როგორც ასეთი, თქვენ
              მიერ გადაცემული ნებისმიერი კონტრიბუცია შეიძლება განიხილებოდეს
              საიტის კონფიდენციალურობის პოლიტიკის შესაბამისად. როდესაც თქვენ
              ქმნით ან ხელმისაწვდომს გახდით რაიმე კონტრიბუციას, თქვენ
              წარმოადგენთ და იძლევით გარანტიას, რომ: შექმნა, გავრცელება,
              გადაცემა, საჯარო ჩვენება ან შესრულება და თქვენი წვლილის წვდომა,
              ჩამოტვირთვა ან კოპირება არ არღვევს და არ დაარღვევს საკუთრების
              უფლებებს, მათ შორის, მაგრამ არ შემოიფარგლება საავტორო უფლებით,
              პატენტით, სავაჭრო ნიშნით, სავაჭრო საიდუმლოებით ან ნებისმიერი
              მესამე მხარის მორალური უფლებები. შექმნა, გავრცელება, გადაცემა,
              საჯარო ჩვენება ან შესრულება და თქვენი წვლილის წვდომა, ჩამოტვირთვა
              ან კოპირება არ არღვევს და არ დაარღვევს საკუთრების უფლებებს, მათ
              შორის, მაგრამ არ შემოიფარგლება საავტორო უფლებით, პატენტით, სავაჭრო
              ნიშნით, სავაჭრო საიდუმლოებით ან ნებისმიერი მესამე მხარის მორალური
              უფლებები. თქვენ ხართ შემოქმედი და მფლობელი ან გაქვთ საჭირო
              ლიცენზიები, უფლებები, თანხმობა, გამოშვებები და ნებართვები, რომ
              გამოიყენოთ და უფლება მოგვცეთ ჩვენ, საიტის და საიტის სხვა
              მომხმარებლებს, გამოვიყენოთ თქვენი წვლილი ნებისმიერი სახით, როგორც
              ეს არის გათვალისწინებული საიტი და ეს Მოხმარების პირობები. თქვენ
              გაქვთ წერილობითი თანხმობა, გათავისუფლება ან/და თითოეული
              იდენტიფიცირებადი პიროვნების ნებართვა თქვენს კონტრიბუციაში,
              გამოიყენოს თითოეული ასეთი იდენტიფიცირებადი ინდივიდუალური პირის
              სახელი ან მსგავსება, რათა მოხდეს თქვენი წვლილის ჩართვა და
              გამოყენება ნებისმიერი სახით, როგორც ეს არის გათვალისწინებული საიტი
              და ეს გამოყენების წესები. თქვენი წვლილი არ არის ყალბი, არაზუსტი ან
              შეცდომაში შემყვანი. თქვენი წვლილი არ არის არასასურველი ან
              არაავტორიზებული რეკლამა, სარეკლამო მასალები, პირამიდული სქემები,
              ჯაჭვური წერილები, სპამი, მასობრივი ფოსტით გაგზავნა ან მოთხოვნის
              სხვა ფორმები. თქვენი წვლილი არ არის უხამსი, უხამსი, გარყვნილი,
              ბინძური, ძალადობრივი, შეურაცხმყოფელი, ცილისმწამებლური,
              ცილისმწამებლური ან სხვაგვარად შეურაცხმყოფელი (ჩვენს მიერ
              განსაზღვრული). თქვენი წვლილი არავის დასცინის, დაცინვას,
              შეურაცხყოფას, დაშინებას ან შეურაცხყოფას არ აყენებს. თქვენი წვლილი
              არ გამოიყენება სხვა პირის შევიწროების ან მუქარისთვის (ამ
              ტერმინების იურიდიული გაგებით) და კონკრეტული პირის ან ადამიანთა
              კლასის მიმართ ძალადობის ხელშეწყობისთვის. თქვენი წვლილი არ არღვევს
              მოქმედ კანონს, რეგულაციას ან წესს. თქვენი წვლილი არ არღვევს მესამე
              მხარის კონფიდენციალურობის ან საჯაროობის უფლებებს. თქვენი წვლილი არ
              არღვევს რაიმე მოქმედ კანონს ბავშვთა პორნოგრაფიასთან დაკავშირებით,
              ან სხვაგვარად მიზნად ისახავს არასრულწლოვანთა ჯანმრთელობისა და
              კეთილდღეობის დაცვას. თქვენი წვლილი არ შეიცავს შეურაცხმყოფელ
              კომენტარებს, რომლებიც დაკავშირებულია რასასთან, ეროვნულ
              წარმომავლობასთან, სქესთან, სექსუალურ უპირატესობებთან ან ფიზიკურ
              შეზღუდვებთან. თქვენი წვლილი სხვაგვარად არ არღვევს ან არ აკავშირებს
              მასალას, რომელიც არღვევს ამ გამოყენების პირობების რომელიმე
              დებულებას ან მოქმედ კანონს ან რეგულაციას. საიტის ნებისმიერი
              გამოყენება ზემოაღნიშნულის დარღვევით არღვევს ამ გამოყენების წესებს
              და შეიძლება გამოიწვიოს, სხვა საკითხებთან ერთად, შეწყდეს ან
              შეჩერდეს საიტის გამოყენების თქვენი უფლებები.
            </p>
          </li>
          <li>
            <h3>6. წვლილი შეტანილი ლიცენზია</h3>
            <p>
              თქვენ თანახმა ხართ, რომ ჩვენ შეგვიძლია მივიღოთ წვდომა, შევინახოთ,
              დავამუშაოთ და გამოვიყენოთ ნებისმიერი ინფორმაცია და პერსონალური
              მონაცემები, რომლებსაც თქვენ მოგვაწვდით კონფიდენციალურობის
              პოლიტიკის პირობებისა და თქვენი არჩევანის (პარამეტრების ჩათვლით)
              შესაბამისად. საიტთან დაკავშირებით წინადადებების ან სხვა
              გამოხმაურების წარდგენით, თქვენ ეთანხმებით, რომ ჩვენ შეგვიძლია
              გამოვიყენოთ და გავაზიაროთ ასეთი გამოხმაურება ნებისმიერი მიზნით
              თქვენთვის კომპენსაციის გარეშე. ჩვენ არ ვამტკიცებთ რაიმე საკუთრებას
              თქვენს წვლილს. თქვენ ინარჩუნებთ სრულ მფლობელობას თქვენს ყველა
              კონტრიბუციაზე და ინტელექტუალური საკუთრების ნებისმიერ უფლებაზე ან
              თქვენს წვლილებთან დაკავშირებულ სხვა საკუთრების უფლებაზე. ჩვენ არ
              ვართ პასუხისმგებელი თქვენს მიერ მოწოდებულ თქვენს მიერ მოწოდებულ
              კონტრიბუციებზე რაიმე განცხადებებზე ან წარმომადგენლობაზე საიტის
              ნებისმიერ სფეროში. თქვენ ხართ მხოლოდ პასუხისმგებელი თქვენს წვლილზე
              საიტზე და თქვენ პირდაპირ ეთანხმებით გათავისუფლდეთ ჩვენ ნებისმიერი
              პასუხისმგებლობისგან და თავი შეიკავოთ ჩვენს წინააღმდეგ რაიმე
              სამართლებრივი ქმედებებისგან თქვენს წვლილებთან დაკავშირებით.
            </p>
          </li>
          <li>
            <h3>7. სახელმძღვანელო</h3>
            <p>
              ჩვენ შეიძლება მოგაწოდოთ უბნები საიტზე, რომ დატოვოთ მიმოხილვები ან
              რეიტინგები. მიმოხილვის გამოქვეყნებისას თქვენ უნდა შეასრულოთ
              შემდეგი კრიტერიუმები:<br/> (1) უნდა გქონდეთ უშუალო გამოცდილება განხილულ
              პირთან/ობიექტთან;<br/> (2) თქვენი მიმოხილვები არ უნდა შეიცავდეს
              შეურაცხმყოფელ ლანძღვას, ან შეურაცხმყოფელ, რასისტულ, შეურაცხმყოფელ
              ან სიძულვილის ენას;<br/> (3) თქვენი მიმოხილვები არ უნდა შეიცავდეს
              დისკრიმინაციულ მითითებებს რელიგიის, რასის, სქესის, ეროვნული
              წარმომავლობის, ასაკის, ოჯახური მდგომარეობის, სექსუალური
              ორიენტაციის ან ინვალიდობის საფუძველზე;<br/> (4) თქვენი მიმოხილვები არ
              უნდა შეიცავდეს მითითებებს უკანონო ქმედებებზე;<br/> (5) თქვენ არ უნდა
              იყოთ დაკავშირებული კონკურენტებთან, თუ აქვეყნებთ უარყოფით
              მიმოხილვებს;<br/> (6) თქვენ არ უნდა გააკეთოთ რაიმე დასკვნები ქცევის
              კანონიერების შესახებ;<br/> (7) თქვენ არ შეგიძლიათ განათავსოთ რაიმე ცრუ
              ან შეცდომაში შემყვანი განცხადება; <br/> (8) თქვენ არ შეგიძლიათ
              მოაწყოთ კამპანია, რომელიც წაახალისებს სხვებს გამოაქვეყნონ
              მიმოხილვები, დადებითი თუ უარყოფითი.<br/> ჩვენ შეიძლება მივიღოთ, უარვყოთ
              ან წავშალოთ მიმოხილვები ჩვენი შეხედულებისამებრ. ჩვენ აბსოლუტურად
              არ გვაქვს ვალდებულება გამოვყოთ მიმოხილვები ან წავშალოთ
              მიმოხილვები, მაშინაც კი, თუ ვინმე მიიჩნევს, რომ მიმოხილვები
              არასათანადო ან არაზუსტია. მიმოხილვები არ არის მოწონებული ჩვენ მიერ
              და არ წარმოადგენს აუცილებლად ჩვენს მოსაზრებებს ან ჩვენი რომელიმე
              პარტნიორის ან პარტნიორის შეხედულებებს. ჩვენ არ ვიღებთ
              პასუხისმგებლობას რაიმე განხილვისთვის ან რაიმე პრეტენზიის,
              ვალდებულების ან ზარალის შესახებ, რომელიც გამოწვეულია ნებისმიერი
              განხილვისას. მიმოხილვის გამოქვეყნებით, თქვენ გვაძლევთ მუდმივ,
              არაექსკლუზიურ, მსოფლიო მასშტაბით, ჰონორარის გარეშე, სრულად
              გადახდილ, მინიჭებულ და ქველიცენზირებად უფლებას და ლიცენზიას
              რეპროდუცირების, შეცვლის, თარგმნის, გადაცემის ნებისმიერი
              საშუალებით, ჩვენების, შესრულების, და/ან გაავრცელეთ მიმოხილვასთან
              დაკავშირებული მთელი კონტენტი.
            </p>
          </li>
          <li>
            <h3>8. სოციალური მედია</h3>
            <p>
              როგორც საიტის ფუნქციონირების ნაწილი, შეგიძლიათ დააკავშიროთ თქვენი
              ანგარიში ონლაინ ანგარიშებთან, რომლებიც გაქვთ მესამე მხარის
              სერვისის პროვაიდერებთან (თითოეული ასეთი ანგარიში, „მესამე მხარის
              ანგარიში“) ან:<br/> (1) მიაწოდოთ თქვენი მესამე მხარის ანგარიში შესვლის
              ინფორმაცია საიტის მეშვეობით;<br/> ან (2) საშუალებას გვაძლევს შევიდეთ
              თქვენს მესამე მხარის ანგარიშზე, როგორც ეს ნებადართულია მოქმედი
              პირობებით, რომლებიც არეგულირებს თქვენს მიერ თითოეული მესამე მხარის
              ანგარიშის გამოყენებას.<br/> თქვენ აცხადებთ და იძლევით გარანტიას, რომ
              უფლება გაქვთ გაამჟღავნოთ თქვენი მესამე მხარის ანგარიშზე შესვლის
              ინფორმაცია და/ან მოგვცეთ წვდომა თქვენს მესამე მხარის ანგარიშზე,
              თქვენს მიერ რაიმე პირობებისა და პირობების დარღვევის გარეშე,
              რომლებიც არეგულირებს თქვენს მიერ მოქმედი მესამე მხარის ანგარიში და
              ჩვენ არ გვავალდებულებს გადაიხადოთ რაიმე საკომისიო ან დაგვაქვეითოს
              გამოყენების შეზღუდვები, რომლებიც დაწესებულია მესამე მხარის
              ანგარიშის მესამე მხარის სერვისის პროვაიდერის მიერ. მესამე მხარის
              ნებისმიერ ანგარიშზე წვდომის მინიჭებით, თქვენ გესმით, რომ (1) ჩვენ
              შეგვიძლია წვდომა, ხელმისაწვდომი გავხადოთ და შეინახოთ (ასეთის
              არსებობის შემთხვევაში) ნებისმიერი კონტენტი, რომელიც თქვენ მიაწოდეთ
              და შეინახეთ თქვენს მესამე მხარის ანგარიშში („სოციალური ქსელი“
              შიგთავსი“), რათა ის ხელმისაწვდომი იყოს საიტზე და მისი მეშვეობით
              თქვენი ანგარიშის მეშვეობით, მათ შორის მეგობრების სიების შეზღუდვის
              გარეშე და (2) ჩვენ შეგვიძლია მივაწოდოთ და მივიღოთ თქვენი მესამე
              მხარის ანგარიშიდან დამატებითი ინფორმაცია იმ მოცულობით, რამდენადაც
              შეგატყობინებთ, როცა ბმულს იღებთ თქვენი ანგარიში მესამე მხარის
              ანგარიშით. თქვენს მიერ არჩეული მესამე მხარის ანგარიშებიდან
              გამომდინარე და ექვემდებარება კონფიდენციალურობის პარამეტრებს,
              რომლებიც დაყენებულია მესამე მხარის ანგარიშებში, პერსონალური
              იდენტიფიცირებადი ინფორმაცია, რომელსაც თქვენ განათავსებთ თქვენს
              მესამე მხარის ანგარიშებზე, შეიძლება ხელმისაწვდომი იყოს თქვენს
              ანგარიშზე და მისი მეშვეობით საიტზე. გთხოვთ, გაითვალისწინოთ, რომ თუ
              მესამე მხარის ანგარიში ან მასთან დაკავშირებული სერვისი
              მიუწვდომელია ან მესამე მხარის სერვისის პროვაიდერის მიერ
              შეწყვეტილია ჩვენი წვდომა ამ მესამე მხარის ანგარიშზე, მაშინ
              სოციალური ქსელის კონტენტი შეიძლება აღარ იყოს ხელმისაწვდომი საიტზე
              და მისი მეშვეობით. თქვენ გექნებათ შესაძლებლობა გამორთოთ კავშირი
              თქვენს ანგარიშსა და თქვენს მესამე მხარის ანგარიშებს შორის
              ნებისმიერ დროს. გთხოვთ, გაითვალისწინოთ, რომ თქვენი ურთიერთობა
              მესამე მხარის სერვისის პროვაიდერებთან, რომლებიც დაკავშირებულია
              თქვენს მესამე მხარის ანგარიშებთან, რეგულირდება მხოლოდ თქვენი
              შეთანხმებით (შეთანხმებებით) ასეთ მესამე მხარის სერვისების
              პროვაიდერებთან. ჩვენ არ ვცდილობთ გადავხედოთ ნებისმიერი სოციალური
              ქსელის კონტენტს რაიმე მიზნით, მათ შორის, მაგრამ არ შემოიფარგლება,
              სიზუსტისთვის, კანონიერების ან არადარღვევისთვის, და ჩვენ არ ვართ
              პასუხისმგებელი სოციალური ქსელის ნებისმიერ კონტენტზე. თქვენ
              აღიარებთ და ეთანხმებით, რომ ჩვენ შეგვიძლია წვდომა მივიღოთ თქვენი
              ელ.ფოსტის მისამართების წიგნში, რომელიც დაკავშირებულია მესამე
              მხარის ანგარიშთან და თქვენს მობილურ მოწყობილობაში ან ტაბლეტ
              კომპიუტერზე შენახული თქვენი კონტაქტების სიაში, მხოლოდ იმ
              კონტაქტების იდენტიფიკაციისა და ინფორმირების მიზნით, რომლებიც ასევე
              დარეგისტრირდნენ საიტის გამოსაყენებლად. . თქვენ შეგიძლიათ გამორთოთ
              კავშირი საიტსა და თქვენს მესამე მხარის ანგარიშს შორის
              დაგვიკავშირდით ქვემოთ მოცემული საკონტაქტო ინფორმაციის გამოყენებით
              ან თქვენი ანგარიშის პარამეტრების მეშვეობით (ასეთის არსებობის
              შემთხვევაში).
            </p>
          </li>
          <li>
            <h3>9. წინადადებები</h3>
            <p>
              თქვენ აღიარებთ და ეთანხმებით, რომ ნებისმიერი შეკითხვა, კომენტარი,
              წინადადება, იდეა, გამოხმაურება ან სხვა ინფორმაცია თქვენს მიერ
              ჩვენთვის მოწოდებული საიტთან დაკავშირებით არაკონფიდენციალურია და
              გახდება ჩვენი ერთადერთი საკუთრება. ჩვენ გვექნება ექსკლუზიური
              უფლებები, მათ შორის ყველა ინტელექტუალური საკუთრების უფლება და
              გვექნება უფლება, შეუზღუდავი გამოვიყენოთ და გავავრცელოთ ეს
              წარდგინება ნებისმიერი კანონიერი მიზნისთვის, კომერციული თუ
              სხვაგვარად, თქვენი აღიარების ან კომპენსაციის გარეშე. ამით თქვენ
              უარს იტყვით ყველა მორალურ უფლებაზე ნებისმიერ ასეთ წარდგენაზე და
              ამით იძლევით გარანტიას, რომ ნებისმიერი ასეთი წარდგინება
              ორიგინალურია თქვენთან ერთად ან რომ გაქვთ უფლება, წარადგინოთ ასეთი
              წარდგინებები. თქვენ ეთანხმებით, რომ არ იქნება ჩვენ წინააღმდეგ
              მიმართული რაიმე სახის სავარაუდო ან ფაქტობრივი დარღვევის ან
              მითვისების რაიმე საკუთრების უფლება თქვენს წინადადებებში.
            </p>
          </li>
          <li>
            <h3>10. მესამე მხარის ვებგვერდი და შინაარსი</h3>
            <p>
              საიტი შეიძლება შეიცავდეს (ან შეიძლება გამოგიგზავნოთ საიტის
              საშუალებით) ბმულები სხვა ვებსაიტებზე („მესამე მხარის ვებსაიტები“),
              ასევე სტატიები, ფოტოები, ტექსტი, გრაფიკა, სურათები, დიზაინი,
              მუსიკა, ხმა, ვიდეო, ინფორმაცია, აპლიკაციები. , პროგრამული
              უზრუნველყოფა და სხვა კონტენტი ან ელემენტი, რომელიც ეკუთვნის ან
              მომდინარეობს მესამე მხარეებზე („მესამე მხარის კონტენტი“). ასეთი
              მესამე მხარის ვებსაიტები და მესამე მხარის კონტენტი არ არის
              გამოკვლეული, მონიტორინგი ან შემოწმებული ჩვენს მიერ სიზუსტეზე,
              მიზანშეწონილობაზე ან სისრულეზე და ჩვენ არ ვართ პასუხისმგებელი
              მესამე მხარის ვებსაიტებზე, რომლებიც ხელმისაწვდომია საიტის ან
              მესამე მხარის გამოქვეყნებულ ნებისმიერ კონტენტზე. ხელმისაწვდომია,
              ხელმისაწვდომია ან დაინსტალირებული საიტიდან, მათ შორის მესამე
              მხარის ვებსაიტების ან მესამე მხარის კონტენტის შინაარსი, სიზუსტე,
              შეურაცხყოფა, მოსაზრებები, სანდოობა, კონფიდენციალურობის პრაქტიკა ან
              სხვა პოლიტიკა ან შეიცავს მათ. მესამე მხარის რომელიმე ვებსაიტის ან
              ნებისმიერი მესამე მხარის კონტენტის ჩართვა, დაკავშირება ან
              გამოყენების ან ინსტალაციის ნებართვა არ ნიშნავს ჩვენს მიერ მის
              დამტკიცებას ან მოწონებას. თუ გადაწყვეტთ დატოვოთ საიტი და შეხვიდეთ
              მესამე მხარის ვებსაიტებზე ან გამოიყენოთ ან დააინსტალიროთ
              ნებისმიერი მესამე მხარის კონტენტი, ამას აკეთებთ თქვენი
              პასუხისმგებლობით და უნდა იცოდეთ, რომ ეს გამოყენების პირობები აღარ
              რეგულირდება. თქვენ უნდა გადახედოთ მოქმედ პირობებს და პოლიტიკას,
              მათ შორის კონფიდენციალურობისა და მონაცემთა შეგროვების პრაქტიკას,
              ნებისმიერი ვებსაიტის, რომელზედაც თქვენ ნავიგაციას უწევთ საიტიდან
              ან ეხება ნებისმიერ აპლიკაციას, რომელსაც იყენებთ ან დააინსტალირეთ
              საიტიდან. ნებისმიერი შესყიდვა, რომელსაც თქვენ განახორციელებთ
              მესამე მხარის ვებსაიტების მეშვეობით, განხორციელდება სხვა
              ვებსაიტებიდან და სხვა კომპანიებისგან, და ჩვენ არანაირ
              პასუხისმგებლობას არ ვიღებთ ასეთ შესყიდვებთან დაკავშირებით,
              რომლებიც ექსკლუზიურად არის თქვენსა და მოქმედ მესამე მხარეს შორის.
              თქვენ ეთანხმებით და აცნობიერებთ, რომ ჩვენ არ ვადასტურებთ მესამე
              მხარის ვებსაიტებზე შემოთავაზებულ პროდუქტებს ან სერვისებს და თქვენ
              არ დაგვეჭირება უვნებელი ნებისმიერი ზიანისგან, რომელიც გამოწვეულია
              თქვენ მიერ ასეთი პროდუქტების ან სერვისების შეძენით. გარდა ამისა,
              თქვენ დაგვიცავთ უვნებლად თქვენს მიერ მიყენებული ნებისმიერი
              ზარალისგან ან თქვენთვის მიყენებული ზიანისგან, რომელიც
              დაკავშირებულია მესამე მხარის ნებისმიერ კონტენტთან ან მესამე მხარის
              ვებსაიტებთან რაიმე სახის კონტაქტთან დაკავშირებით.
            </p>
          </li>
          <li>
            <h3>11. საიტის მენეჯმენტი</h3>
            <p>
              ჩვენ ვიტოვებთ უფლებას, მაგრამ არა ვალდებულებას, რომ:<br/> (1)
              ვაკონტროლოთ საიტი წინამდებარე გამოყენების პირობების დარღვევისთვის;
              <br/>(2) მიიღოს შესაბამისი სამართლებრივი ზომები ნებისმიერის წინააღმდეგ,
              ვინც, ჩვენი შეხედულებისამებრ, არღვევს კანონს ან წინამდებარე
              გამოყენების წესებს, მათ შორის, შეუზღუდავად, შეატყობინოს ასეთი
              მომხმარებლის სამართალდამცავ ორგანოებს;<br/> (3) ჩვენი შეხედულებისამებრ
              და შეზღუდვის გარეშე, უარი თქვას, შეზღუდოს წვდომა, შეზღუდოს
              ხელმისაწვდომობა ან გამორთოს (ტექნოლოგიურად შესაძლებელი) ნებისმიერი
              თქვენი წვლილი ან მისი ნაწილი;<br/> (4) ჩვენი შეხედულებისამებრ და
              შეზღუდვის, შეტყობინების ან პასუხისმგებლობის გარეშე, წავშალოთ
              საიტიდან ან სხვაგვარად გამორთოთ ყველა ფაილი და კონტენტი, რომელიც
              გადაჭარბებული ზომისაა ან რაიმე ფორმით ამძიმებს ჩვენს სისტემას;<br/> და
              (5) სხვაგვარად მართოთ საიტი ისე, რომ დავიცვათ ჩვენი უფლებები და
              საკუთრება და ხელი შევუწყოთ საიტის გამართულ ფუნქციონირებას.
            </p>
          </li>
          <li>
            <h3>12. კონფიდენციალურობის პოლიტიკა</h3>
            <p>
              ეს გამოყენების პირობები დარჩება სრული ძალაში, სანამ თქვენ იყენებთ
              საიტს. ამ გამოყენების პირობების რაიმე სხვა დებულების შეზღუდვის
              გარეშე, ჩვენ ვიტოვებთ უფლებას, ჩვენი შეხედულებისამებრ და
              გაფრთხილების გარეშე ან პასუხისმგებლობის გარეშე, უარვყოფთ საიტის
              წვდომასა და გამოყენებას (გარკვეული IP მისამართების ბლოკირების
              ჩათვლით), ნებისმიერ პირს რაიმე მიზეზით ან ყოველგვარი მიზეზის
              გარეშე, მათ შორის, ნებისმიერი წარმოდგენის, გარანტიის ან
              შეთანხმების დარღვევის შეზღუდვის გარეშე, რომელიც შეიცავს ამ
              გამოყენების პირობებს ან რაიმე მოქმედ კანონს ან რეგლამენტს. ჩვენ
              შეგვიძლია შევწყვიტოთ თქვენი გამოყენება ან მონაწილეობა საიტში, ან
              წავშალოთ ნებისმიერი კონტენტი ან ინფორმაცია, რომელიც თქვენ
              გამოაქვეყნეთ ნებისმიერ დროს, გაფრთხილების გარეშე, ჩვენი
              შეხედულებისამებრ. თუ ჩვენ ვწყვეტთ ან შევაჩერებთ თქვენს ანგარიშს
              რაიმე მიზეზით, თქვენ გეკრძალებათ დარეგისტრირდეთ და შექმნათ ახალი
              ანგარიში თქვენი სახელით, ყალბი ან ნასესხები სახელით, ან რომელიმე
              მესამე მხარის სახელით, მაშინაც კი, თუ თქვენ შეიძლება მოქმედებდეთ
              მესამე მხარის სახელით. წვეულება. თქვენი ანგარიშის შეწყვეტის ან
              შეჩერების გარდა, ჩვენ ვიტოვებთ უფლებას მივიღოთ შესაბამისი
              სამართლებრივი ქმედებები, მათ შორის სამოქალაქო, სისხლის სამართლის
              და სასამართლო გადაწყვეტილების მიღების შეზღუდვის გარეშე.
            </p>
          </li>
          <li>
            <h3>13. ვადა და შეწყვეტა</h3>
            <p>
              ჩვენ ვიტოვებთ უფლებას შევცვალოთ, შევცვალოთ ან წავშალოთ საიტის
              შინაარსი ნებისმიერ დროს ან რაიმე მიზეზით ჩვენი შეხედულებისამებრ
              გაფრთხილების გარეშე. თუმცა, ჩვენ არ გვაქვს ვალდებულება განაახლოს
              რაიმე ინფორმაცია ჩვენს საიტზე. ჩვენ ასევე ვიტოვებთ უფლებას
              ნებისმიერ დროს შევცვალოთ ან შევწყვიტოთ საიტის მთელი ან ნაწილი
              შეტყობინების გარეშე. ჩვენ არ ვიქნებით პასუხისმგებელი თქვენ ან
              მესამე მხარის წინაშე ნებისმიერი ცვლილებაზე, ფასის ცვლილებაზე,
              შეჩერებაზე ან საიტის შეწყვეტაზე. ჩვენ არ შეგვიძლია გარანტია, რომ
              საიტი ყოველთვის ხელმისაწვდომი იქნება. ჩვენ შეიძლება გვქონდეს
              ტექნიკის, პროგრამული უზრუნველყოფის ან სხვა პრობლემები ან დაგჭირდეთ
              საიტთან დაკავშირებული ტექნიკური სამუშაოების შესრულება, რაც
              გამოიწვევს შეფერხებებს, შეფერხებებს ან შეცდომებს. ჩვენ ვიტოვებთ
              უფლებას შეცვალოთ, გადახედოთ, განაახლონ, შეაჩეროთ, შეწყვიტოთ ან
              სხვაგვარად შევცვალოთ საიტი ნებისმიერ დროს ან რაიმე მიზეზით
              თქვენთვის შეტყობინების გარეშე. თქვენ ეთანხმებით, რომ ჩვენ არანაირ
              პასუხისმგებლობას არ ვიღებთ რაიმე დანაკარგზე, დაზიანებაზე ან
              უხერხულობაზე, რომელიც გამოწვეულია თქვენ მიერ საიტის წვდომის ან
              გამოყენების შეუძლებლობის გამო, საიტის ნებისმიერი შეფერხების ან
              შეწყვეტის დროს. ამ გამოყენების წესებში არაფერი არ იქნება გაგებული
              ისე, რომ გვავალდებულებს შევინარჩუნოთ და მხარი დავუჭიროთ საიტს ან
              მივაწოდოთ რაიმე შესწორება, განახლება ან გამოშვება მასთან
              დაკავშირებით.
            </p>
          </li>
          <li>
            <h3>14. ცვლილებები და შეფერხებები</h3>
            <p>
              იმისათვის, რომ დააჩქაროს და გააკონტროლოს ნებისმიერი დავის, დავის
              ან პრეტენზიის ღირებულება, რომელიც დაკავშირებულია ამ გამოყენების
              პირობებთან (თითოეული „დავა“ და ერთობლივად, „დავები“), რომელიც
              წამოყენებულია თქვენ ან ჩვენ მიერ (ინდივიდუალურად, „მხარე. და
              ერთობლივად, „მხარეები“), მხარეები თანხმდებიან, რომ პირველ რიგში
              სცადონ მოლაპარაკება ნებისმიერ დავის (გარდა იმ დავებისა, რომლებიც
              პირდაპირ მოცემულია ქვემოთ) არაფორმალურად არბიტრაჟის დაწყებამდე
              მინიმუმ ოცდაათი (30) დღით ადრე. ასეთი არაფორმალური მოლაპარაკებები
              იწყება ერთი მხარის მეორე მხარის წერილობითი შეტყობინების შემდეგ.
              სავალდებულო არბიტრაჟი ნებისმიერი დავა, რომელიც წარმოიქმნება ამ
              ხელშეკრულებიდან ან მასთან დაკავშირებით, მათ შორის ნებისმიერი
              საკითხი მის არსებობასთან, მოქმედების ან შეწყვეტასთან დაკავშირებით,
              განიხილება და საბოლოოდ გადაწყვეტს საერთაშორისო კომერციულ
              საარბიტრაჟო სასამართლოს ევროპის საარბიტრაჟო პალატასთან (ბელგია,
              ბრიუსელი, გამზირი). Louise, 146) ამ ICAC-ის წესების მიხედვით,
              რომელიც მასზე მითითების შედეგად განიხილება ამ პუნქტის ნაწილად.
              შეზღუდვები მხარეები თანხმდებიან, რომ ნებისმიერი არბიტრაჟი
              შემოიფარგლება მხოლოდ მხარეებს შორის არსებული დავით. კანონით
              დაშვებული სრულად,<br/> (a) არბიტრაჟი არ უნდა შეუერთდეს სხვა საქმის
              წარმოებას;<br/> (ბ) არ არსებობს რაიმე დავის უფლება ან უფლებამოსილება
              არბიტრაჟის ჯგუფური სარჩელის საფუძველზე ან გამოიყენოს ჯგუფური
              სარჩელის პროცედურები;<br/> და (c) არ არსებობს უფლება ან უფლებამოსილება
              რაიმე დავის წარმომადგენლობით წარმომადგენლობით, ფართო საზოგადოების
              ან ნებისმიერი სხვა პირის სახელით. გამონაკლისები არაფორმალურ
              მოლაპარაკებებსა და არბიტრაჟში მხარეები თანხმდებიან, რომ შემდეგი
              დავები არ ექვემდებარება ზემოხსენებულ დებულებებს არაფორმალურ
              მოლაპარაკებებსა და სავალდებულო არბიტრაჟთან დაკავშირებით:<br/> (a)
              ნებისმიერი დავა, რომელიც ცდილობს განახორციელოს ან დაიცვას მხარის
              რომელიმე ინტელექტუალური საკუთრების უფლება;<br/> (ბ) ნებისმიერი დავა,
              რომელიც დაკავშირებულია ან გამომდინარეობს ქურდობის, მეკობრეობის,
              პირადი ცხოვრების ხელყოფის ან არაავტორიზებული გამოყენების შესახებ
              ბრალდებებთან;<br/> და (გ) ნებისმიერი სარჩელი სასამართლო გადაწყვეტილების
              შესახებ.<br/> თუ ეს დებულება აღმოჩნდება, რომ არაკანონიერია ან
              არააღსასრულებელი, მაშინ არცერთი მხარე არ აირჩევს არბიტრაჟის
              განხილვას ნებისმიერი დავის მიმართ, რომელიც მიეკუთვნება ამ
              დებულების იმ ნაწილს, რომელიც აღმოჩენილია უკანონო ან
              არააღსასრულებლად და ასეთი დავა გადაწყდება კომპეტენტური
              იურისდიქციის სასამართლოს მიერ ჩამოთვლილ სასამართლოებში.
              იურისდიქცია ზემოთ, და მხარეები თანხმდებიან დაემორჩილონ ამ
              სასამართლოს პირად იურისდიქციას.
            </p>
          </li>
          <li>
            <h3>15. დავის გადაწყვეტა</h3>
            <p>
              საიტზე შეიძლება იყოს ინფორმაცია, რომელიც შეიცავს ტიპოგრაფიულ
              შეცდომებს, უზუსტობებს ან გამოტოვებებს, მათ შორის აღწერილობებს,
              ფასებს, ხელმისაწვდომობას და სხვა სხვა ინფორმაციას. ჩვენ ვიტოვებთ
              უფლებას გამოვასწოროთ ნებისმიერი შეცდომა, უზუსტობა ან გამოტოვება და
              შევცვალოთ ან განაახლოთ ინფორმაცია საიტზე ნებისმიერ დროს, წინასწარი
              შეტყობინების გარეშე.
            </p>
          </li>
          
          <li>
            <h3>16. შეზღუდვა</h3>
            <p>
              არავითარ შემთხვევაში არ ვიქნებით პასუხისმგებელი ჩვენ ან ჩვენი
              დირექტორები, თანამშრომლები ან აგენტები თქვენს წინაშე ან რომელიმე
              მესამე პირის წინაშე რაიმე პირდაპირი, არაპირდაპირი, თანმიმდევრული,
              გამომწვევი, შემთხვევითი, შემთხვევითი. LUDING დაკარგული მოგება,
              დაკარგული შემოსავალი , მონაცემების დაკარგვა ან სხვა ზიანი, რომელიც
              წარმოიქმნება თქვენი საიტის გამოყენების შედეგად, მაშინაც კი, თუ
              ჩვენ გვქონდა ცნობა ასეთი ზიანის შესაძლებლობის შესახებ.
            </p>
          </li>
          <li>
            <h3>17. ანაზღაურება</h3>
            <p>
              თქვენ თანახმა ხართ დაიცვათ, აანაზღაუროთ და დაგვანახოთ უვნებლად,
              მათ შორის, ჩვენი შვილობილი კომპანიების, შვილობილი კომპანიების და
              ყველა ჩვენი შესაბამისი ოფიცრის, აგენტის, პარტნიორისა და
              თანამშრომლისგან, ნებისმიერი ზარალის, დაზიანების, პასუხისმგებლობის,
              პრეტენზიის ან მოთხოვნის წინააღმდეგ, მათ შორის გონივრული ადვოკატის
              საფასური და ხარჯები, რომლებიც გაწეულია ნებისმიერი მესამე მხარის
              მიერ:<br/> (1) საიტის გამოყენების გამო;<br/> (2) ამ გამოყენების პირობების
              დარღვევა;<br/> (3) თქვენი წარმომადგენლობისა და გარანტიების ნებისმიერი
              დარღვევა, რომელიც მითითებულია ამ გამოყენების წესებში;<br/> (4) თქვენ
              მიერ მესამე მხარის უფლებების დარღვევა, მათ შორის, მაგრამ არ
              შემოიფარგლება ინტელექტუალური საკუთრების უფლებებით;<br/> ან (5)
              ნებისმიერი აშკარა მავნე მოქმედება საიტის ნებისმიერი სხვა
              მომხმარებლის მიმართ, ვისთანაც თქვენ დაუკავშირდით საიტის მეშვეობით.<br/>
              მიუხედავად ზემოაღნიშნულისა, ჩვენ ვიტოვებთ უფლებას, თქვენი ხარჯით,
              ვიკისროთ ექსკლუზიური დაცვა და კონტროლი ნებისმიერ საკითხზე,
              რომლისთვისაც თქვენ მოგიწევთ ჩვენი ანაზღაურება და თქვენ თანახმა
              ხართ ითანამშრომლოთ, თქვენი ხარჯებით, ჩვენს დაცვაში ასეთი
              პრეტენზიების შესახებ. ჩვენ გამოვიყენებთ გონივრულ ძალისხმევას, რათა
              შეგატყობინოთ ნებისმიერი ასეთი პრეტენზიის, ქმედების ან პროცესის
              შესახებ, რომელიც ექვემდებარება ამ ანაზღაურებას, როდესაც ამის
              შესახებ გავიგებთ.
            </p>
          </li>
          <li>
            <h3>18. მომხმარებლის მონაცემები</h3>
            <p>
              ჩვენ შევინარჩუნებთ გარკვეულ მონაცემებს, რომლებსაც თქვენ გადასცემთ
              საიტს საიტის მუშაობის მართვის მიზნით, ასევე თქვენს მიერ საიტის
              გამოყენებასთან დაკავშირებულ მონაცემებს. მიუხედავად იმისა, რომ ჩვენ
              ვაკეთებთ მონაცემთა რეგულარულ რუტინულ სარეზერვო ასლებს, თქვენ ხართ
              მხოლოდ პასუხისმგებელი ყველა მონაცემზე, რომელსაც თქვენ გადასცემთ ან
              რომელიც დაკავშირებულია თქვენს მიერ განხორციელებულ ნებისმიერ
              საქმიანობასთან საიტის გამოყენებით. თქვენ ეთანხმებით, რომ ჩვენ არ
              გვექნება პასუხისმგებლობა თქვენს წინაშე ნებისმიერი ასეთი
              მონაცემების დაკარგვის ან კორუფციისთვის და ამით თქვენ უარს იტყვით
              ჩვენს წინააღმდეგ მოქმედების ნებისმიერ უფლებაზე, რომელიც
              გამომდინარეობს ასეთი მონაცემების დაკარგვის ან კორუფციის შედეგად.
            </p>
          </li>
          <li>
            <h3>19. ელექტრონული კომუნიკაციები, გარიგებები და ხელმოწერები</h3>
            <p>
              საიტის მონახულება, ელ. ფოსტის გაგზავნა და ონლაინ ფორმების შევსება
              წარმოადგენს ელექტრონულ კომუნიკაციას. თქვენ თანახმა ხართ მიიღოთ
              ელექტრონული კომუნიკაციები და ეთანხმებით, რომ ყველა შეთანხმება,
              შენიშვნა, გამჟღავნება და სხვა კომუნიკაცია, რომელსაც ჩვენ მოგაწვდით
              ელექტრონულად, ელექტრონული ფოსტით და საიტზე, აკმაყოფილებს ნებისმიერ
              იურიდიულ მოთხოვნას, რომ ასეთი კომუნიკაცია იყოს წერილობითი ფორმით.
              თქვენ ეთანხმებით ელექტრონული ხელმოწერების, კონტრაქტების,
              შეკვეთების და სხვა ჩანაწერების გამოყენებას და შეტყობინებების,
              პოლიტიკის და ჩანაწერების ელექტრონულ მიწოდებას, რომლებიც დაწყებულია
              აშშ-ს მიერ დაწყებული გარიგებების შესახებ. ამით თქვენ უარს იტყვით
              ნებისმიერ უფლებასა და მოთხოვნაზე ნებისმიერი დებულების,
              რეგულაციების, წესების, განკარგულებების ან სხვა კანონების
              შესაბამისად, ნებისმიერ იურისდიქციაში, რომელიც მოითხოვს ორიგინალურ
              ხელმოწერას ან არაელექტრონული ჩანაწერების მიწოდებას ან
              შენარჩუნებას, ან გადახდებს ან კრედიტების მინიჭებას ნებისმიერი სხვა
              საშუალებით. ვიდრე ელექტრონული საშუალებები.
            </p>
          </li>
          <li>
            <h3>20. კალიფორნიის მომხმარებლები და რეზიდენტები</h3>
            <p>
              თუ ჩვენთან რაიმე საჩივარი არ არის დამაკმაყოფილებლად გადაწყვეტილი,
              შეგიძლიათ წერილობით დაუკავშირდეთ კალიფორნიის სამომხმარებლო საქმეთა
              დეპარტამენტის მომხმარებელთა მომსახურების განყოფილების საჩივრების
              დახმარების განყოფილებას 1625 North Market Blvd., Suite N 112,
              Sacramento. , კალიფორნია 95834 ან ტელეფონით (800) 952-5210 ან
              (916) 445-1254.
            </p>
          </li>
          <li>
            <h3>21. სხვადასხვა</h3>
            <p>
              ეს გამოყენების პირობები და ჩვენს მიერ საიტზე ან საიტთან
              დაკავშირებით განთავსებული ნებისმიერი პოლიტიკა ან ოპერაციული წესი
              წარმოადგენს თქვენსა და ჩვენს შორის სრულ შეთანხმებას და
              ურთიერთგაგებას. ჩვენ მიერ წინამდებარე გამოყენების პირობების
              რომელიმე უფლების ან დებულების გამოყენება ან აღსრულება არ
              მოქმედებს, როგორც უარი ამ უფლებაზე ან დებულებაზე. ეს სარგებლობის
              პირობები მოქმედებს კანონით დაშვებული სრულყოფილად. ჩვენ შეგვიძლია
              ნებისმიერ დროს მივაწოდოთ ჩვენი ნებისმიერი ან ყველა უფლება და
              მოვალეობა სხვას. ჩვენ არ ვიქნებით პასუხისმგებელი ან პასუხისმგებელი
              რაიმე დანაკარგზე, დაზიანებაზე, დაგვიანებაზე ან უმოქმედობაზე,
              რომელიც გამოწვეულია ჩვენი გონივრული კონტროლის მიღმა რაიმე მიზეზით.
              თუ წინამდებარე გამოყენების პირობების რომელიმე დებულება ან
              დებულების ნაწილი დადგინდა, რომ არის არაკანონიერი, ბათილი ან
              არააღსასრულებელი, ეს დებულება ან დებულების ნაწილი განიხილება
              წინამდებარე გამოყენების პირობებისგან განცალკევებულად და არ
              იმოქმედებს ნებისმიერი დარჩენილი ნაწილის ვალიდობასა და აღსრულებაზე.
              დებულებები. არ არსებობს ერთობლივი საწარმო, პარტნიორობა, დასაქმება
              ან აგენტური ურთიერთობა, რომელიც შექმნილია თქვენსა და ჩვენს შორის
              წინამდებარე გამოყენების პირობების ან საიტის გამოყენების შედეგად.
              თქვენ ეთანხმებით, რომ ეს გამოყენების პირობები არ იქნება გაგებული
              ჩვენს წინააღმდეგ მათი შედგენის გამო. ამით თქვენ უარს იტყვით
              ნებისმიერ და ყველა დაცვაზე, რომელიც შეიძლება გქონდეთ წინამდებარე
              გამოყენების პირობების ელექტრონულ ფორმაზე და მხარეთა მიერ
              წინამდებარე პირობების შესრულებაზე ხელმოწერის არარსებობის
              საფუძველზე.
            </p>
          </li>
          <li>
            <h3>22. დაგვიკავშირდით</h3>
            <p>
              იმისათვის, რომ გადაწყვიტოთ საჩივარი საიტთან დაკავშირებით ან მიიღოთ
              დამატებითი ინფორმაცია საიტის გამოყენებასთან დაკავშირებით, გთხოვთ
              დაგვიკავშირდეთ.
            </p>
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
