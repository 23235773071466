import { useEffect, useRef } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./Cookie.css";

const Cookie = (props) => {
  //scroll to top
  const ref = useRef(null);
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  });

  return (
    <div ref={ref}>
      <Header />
      <div className="cookie-page container">
        <h1>ქუქიების პოლიტიკა</h1>
        <p>
          ეს ქუქი-პოლიტიკა განმარტავს, თუ როგორ ვიყენებთ ქუქი-ფაილებს და მსგავს
          ტექნოლოგიებს თქვენი ამოსაცნობად, როდესაც ეწვიეთ ჩვენს ვებგვერდებს.
          ზოგიერთ შემთხვევაში ჩვენ შეიძლება გამოვიყენოთ ქუქი-ფაილები პერსონალური
          ინფორმაციის შესაგროვებლად, ან ის გახდება პერსონალური ინფორმაცია, თუ
          მას სხვა ინფორმაციას გავაერთიანებთ.
        </p>
        <ul>
          <li>
            <h2>რა არის ქუქიები?</h2>
            <p>
              ქუქი-ფაილები არის მცირე მონაცემთა ფაილები, რომლებიც განთავსებულია
              თქვენს კომპიუტერში ან მობილურ მოწყობილობაზე, როდესაც თქვენ ეწვევით
              ვებსაიტს. ქუქი-ფაილებს ფართოდ იყენებენ ვებსაიტების მფლობელები,
              რათა მათი ვებსაიტები იმუშაონ, ან უფრო ეფექტურად იმუშაონ, ასევე
              საანგარიშო ინფორმაციის მიწოდებისთვის. ვებსაიტის მფლობელის (ამ
              შემთხვევაში, ჩვენ) მიერ დაყენებულ ქუქი-ფაილებს ეწოდება „პირველი
              მხარის ქუქიები“. ვებსაიტის მფლობელის გარდა სხვა მხარეების მიერ
              დაყენებულ ქუქი-ფაილებს ეწოდება „მესამე მხარის ქუქიები“. მესამე
              მხარის ქუქი-ფაილები იძლევა მესამე მხარის ფუნქციების ან ფუნქციების
              მიწოდებას ვებსაიტზე ან მის მეშვეობით (მაგ. რეკლამა, ინტერაქტიული
              კონტენტი და ანალიტიკა). მხარეებს, რომლებმაც დააყენეს ეს მესამე
              მხარის ქუქი-ფაილები, შეუძლიათ თქვენი კომპიუტერის ამოცნობა როგორც
              სადავო ვებსაიტზე, ასევე ზოგიერთ სხვა ვებსაიტზე.
            </p>
          </li>
          <li>
            <h2>რატომ ვიყენებთ ქუქი-ფაილებს?</h2>
            <p>
              ჩვენ ვიყენებთ პირველი და მესამე მხარის ქუქიებს რამდენიმე მიზეზის
              გამო. ზოგიერთი ქუქი-ფაილი საჭიროა ტექნიკური მიზეზების გამო, რათა
              ჩვენი ვებსაიტები იმუშაონ და ჩვენ მათ ვუწოდებთ, როგორც „არსებით“ ან
              „მკაცრად აუცილებელ“ ქუქი-ფაილებს. სხვა ქუქი-ფაილები ასევე
              საშუალებას გვაძლევს თვალყური ადევნოთ და მივმართოთ ჩვენი
              მომხმარებლების ინტერესებს, რათა გავაუმჯობესოთ გამოცდილება ჩვენს
              ონლაინ თვისებებზე. მესამე მხარეები ემსახურებიან ქუქი-ფაილებს ჩვენი
              ვებსაიტების მეშვეობით სარეკლამო, ანალიტიკისა და სხვა მიზნებისთვის.
              ეს უფრო დეტალურად არის აღწერილი ქვემოთ. პირველი და მესამე მხარის
              ქუქიების სპეციფიკური ტიპები, რომლებიც გამოიყენება ჩვენი
              ვებსაიტების მეშვეობით და მათი მიზნები, აღწერილია ქვემოთ (გთხოვთ,
              გაითვალისწინოთ, რომ მოწოდებული კონკრეტული ქუქიები შეიძლება
              განსხვავდებოდეს თქვენს მიერ მონახულებული ონლაინ თვისებების
              მიხედვით).
            </p>
          </li>
          <li>
            <h2>როგორ გავაკონტროლო ქუქიები?</h2>
            <p>
              თქვენ გაქვთ უფლება გადაწყვიტოთ, მიიღოთ თუ უარყოთ cookies. თქვენ
              შეგიძლიათ განახორციელოთ თქვენი ქუქიების უფლებები ქუქიების
              თანხმობის მენეჯერში თქვენი პრეფერენციების დაყენებით. ქუქიების
              თანხმობის მენეჯერი გაძლევთ საშუალებას აირჩიოთ ქუქიების რომელ
              კატეგორიებს ეთანხმებით ან უარვყოფთ. არსებითი ქუქიების უარყოფა
              შეუძლებელია, რადგან ისინი მკაცრად აუცილებელია თქვენთვის სერვისების
              მოსაწოდებლად. ქუქიების თანხმობის მენეჯერი შეგიძლიათ იხილოთ
              შეტყობინებების ბანერში და ჩვენს ვებსაიტზე. თუ თქვენ აირჩევთ
              ქუქი-ფაილების უარყოფას, შეგიძლიათ კვლავ გამოიყენოთ ჩვენი
              ვებ-გვერდი, თუმცა თქვენი წვდომა ჩვენი ვებსაიტის ზოგიერთ
              ფუნქციონალზე და სფეროზე შეიძლება შეიზღუდოს. თქვენ ასევე შეგიძლიათ
              დააყენოთ ან შეცვალოთ თქვენი ვებ ბრაუზერის კონტროლი ქუქიების
              მიღებაზე ან უარყოფაზე. იმის გამო, რომ საშუალებები, რომლითაც
              შეგიძლიათ უარი თქვათ ქუქიებზე თქვენი ვებ ბრაუზერის კონტროლის
              საშუალებით, განსხვავდება ბრაუზერიდან ბრაუზერამდე, თქვენ უნდა
              ეწვიოთ თქვენი ბრაუზერის დახმარების მენიუს დამატებითი
              ინფორმაციისთვის. გარდა ამისა, სარეკლამო ქსელების უმეტესობა
              გთავაზობს მიზნობრივ რეკლამაზე უარის თქმის საშუალებას. თუ გსურთ
              მეტი ინფორმაციის მიღება, გთხოვთ, ეწვიოთ
              http://www.aboutads.info/choices/ ან
              http://www.youronlinechoices.com. პირველი და მესამე მხარის
              ქუქიების სპეციფიკური ტიპები, რომლებიც მოწოდებულია ჩვენი
              ვებსაიტების მეშვეობით და მათი მიზნები აღწერილია ქვემოთ მოცემულ
              ცხრილში (გთხოვთ, გაითვალისწინოთ, რომ მოწოდებული კონკრეტული ქუქიები
              შეიძლება განსხვავდებოდეს თქვენს მიერ მონახულებული ონლაინ
              თვისებების მიხედვით). ვებსაიტის ძირითადი ქუქი-ფაილები: ეს ქუქიები
              მკაცრად არის საჭირო იმისათვის, რომ მოგაწოდოთ სერვისები, რომლებიც
              ხელმისაწვდომია ჩვენი ვებსაიტების მეშვეობით და გამოიყენოთ მისი
              ზოგიერთი ფუნქცია, როგორიცაა უსაფრთხო ზონებზე წვდომა.
            </p>
          </li>
          <li>
            <h2>
              რაც შეეხება თვალთვალის სხვა ტექნოლოგიებს, როგორიცაა ვებ შუქურები?
            </h2>
            <p>
              ქუქიები არ არის ვებსაიტის ვიზიტორების ამოცნობის ან თვალყურის
              დევნების ერთადერთი გზა. ჩვენ შეიძლება დროდადრო გამოვიყენოთ სხვა,
              მსგავსი ტექნოლოგიები, როგორიცაა ვებ შუქურები (ზოგჯერ უწოდებენ
              „თვალთვალის პიქსელებს“ ან „გასუფთავებულ გიფებს“). ეს არის პაწაწინა
              გრაფიკული ფაილები, რომლებიც შეიცავს უნიკალურ იდენტიფიკატორს,
              რომელიც საშუალებას გვაძლევს ამოვიცნოთ, როდესაც ვინმე ეწვია ჩვენს
              ვებსაიტებს ან გახსნა ელექტრონული ფოსტა მათ შორის. ეს საშუალებას
              გვაძლევს, მაგალითად, დავაკვირდეთ მომხმარებელთა ტრაფიკის შაბლონებს
              ვებსაიტის ერთი გვერდიდან მეორეზე, მივაწოდოთ ან დაუკავშირდეთ ქუქი
              ფაილებს, გავიგოთ, მოხვედით თუ არა საიტზე მესამე მხარის ვებსაიტზე
              ნაჩვენები ონლაინ რეკლამიდან. , საიტის მუშაობის გასაუმჯობესებლად და
              ელექტრონული ფოსტის მარკეტინგული კამპანიების წარმატების გასაზომად.
              ხშირ შემთხვევაში, ეს ტექნოლოგიები ეყრდნობა ქუქი-ფაილებს, რათა
              სწორად იმუშაონ და, შესაბამისად, ქუქი-ფაილების უარყოფა შეაფერხებს
              მათ ფუნქციონირებას.
            </p>
          </li>
          <li>
            <h2>იყენებთ Flash ქუქიებს ან ადგილობრივ გაზიარებულ ობიექტებს?</h2>
            <p>
              ვებსაიტებმა ასევე შეიძლება გამოიყენონ ეგრეთ წოდებული „Flash
              Cookies“ (ასევე ცნობილი როგორც ლოკალური გაზიარებული ობიექტები ან
              „LSOs“), სხვა საკითხებთან ერთად, შეაგროვოს და შეინახოს ინფორმაცია
              თქვენს მიერ ჩვენი სერვისების გამოყენების, თაღლითობის პრევენციისა
              და საიტის სხვა ოპერაციების შესახებ. თუ არ გსურთ Flash Cookies-ის
              შენახვა თქვენს კომპიუტერში, შეგიძლიათ შეცვალოთ თქვენი Flash
              Player-ის პარამეტრები Flash Cookies-ის შენახვის დაბლოკვის მიზნით
              ვებსაიტის შენახვის პარამეტრების პანელში მოცემული ხელსაწყოების
              გამოყენებით. თქვენ ასევე შეგიძლიათ მართოთ Flash Cookies გლობალური
              მეხსიერების პარამეტრების პანელზე გადასვლით და ინსტრუქციების
              მიყოლებით (რომელიც შეიძლება შეიცავდეს ინსტრუქციებს, რომლებიც
              განმარტავს, მაგალითად, როგორ წაშალოთ არსებული Flash Cookies
              (მოხსენიებულია „ინფორმაცია“ მაკრომედიის საიტზე), როგორ თავიდან
              აიცილოთ Flash LSO-ების განთავსება თქვენს კომპიუტერში თქვენი
              მოთხოვნის გარეშე და (Flash Player 8 და უფრო ახალი ვერსიებისთვის)
              როგორ დაბლოკოთ Flash ქუქი ფაილები, რომლებიც არ არის მიწოდებული იმ
              გვერდის ოპერატორის მიერ, რომელზეც იმყოფებით იმ დროს). გთხოვთ,
              გაითვალისწინოთ, რომ Flash Player-ის დაყენებამ შეზღუდოს ან შეზღუდოს
              Flash Cookies-ის მიღება, შეიძლება შეამციროს ან შეაფერხოს ზოგიერთი
              Flash აპლიკაციის ფუნქციონირება, მათ შორის, პოტენციურად, Flash
              აპლიკაციების, რომლებიც გამოიყენება ჩვენს სერვისებთან ან ონლაინ
              კონტენტთან დაკავშირებით.
            </p>
          </li>
          <li>
            <h2>ემსახურებით მიზნობრივ რეკლამას?</h2>
            <p>
              მესამე მხარეებს შეუძლიათ თქვენს კომპიუტერზე ან მობილურ
              მოწყობილობაზე ქუქი-ფაილების მოწოდება ჩვენი ვებსაიტების მეშვეობით
              რეკლამის გასაკეთებლად. ამ კომპანიებმა შეიძლება გამოიყენონ
              ინფორმაცია თქვენი ვიზიტების შესახებ ამ და სხვა ვებსაიტებზე, რათა
              მოგაწოდონ შესაბამისი რეკლამა საქონლისა და სერვისის შესახებ,
              რომელიც შეიძლება დაგაინტერესოთ. მათ ასევე შეუძლიათ გამოიყენონ
              ტექნოლოგია, რომელიც გამოიყენება რეკლამის ეფექტურობის გასაზომად. ეს
              შეიძლება განხორციელდეს მათ მიერ ქუქიების ან ვებ შუქურების
              გამოყენებით, რათა შეაგროვონ ინფორმაცია თქვენი ვიზიტების შესახებ ამ
              და სხვა საიტებზე, რათა უზრუნველყონ შესაბამისი რეკლამა თქვენთვის
              საინტერესო საქონლისა და მომსახურების შესახებ. ამ პროცესის
              მეშვეობით შეგროვებული ინფორმაცია ჩვენ ან მათ არ გვაძლევს
              საშუალებას, ამოვიცნოთ თქვენი სახელი, საკონტაქტო ინფორმაცია ან სხვა
              დეტალები, რომლებიც უშუალოდ იდენტიფიცირებთ, თუ არ გადაწყვეტთ მათ
              მიწოდებას.
            </p>
          </li>
          <li>
            <h2>რამდენად ხშირად განაახლებთ ამ ქუქიების პოლიტიკას?</h2>
            <p>
              ჩვენ შეიძლება დროდადრო განვაახლოთ ქუქიების პოლიტიკა, რათა აისახოს,
              მაგალითად, ჩვენ მიერ გამოყენებული ქუქიების ცვლილებები ან სხვა
              ოპერაციული, სამართლებრივი ან მარეგულირებელი მიზეზების გამო.
              ამიტომ, გთხოვთ, რეგულარულად ეწვიოთ ამ ქუქიების პოლიტიკას, რათა
              იყოთ ინფორმირებული ქუქიების და მასთან დაკავშირებული ტექნოლოგიების
              გამოყენების შესახებ. ამ ქუქიების პოლიტიკის ზედა ნაწილში მითითებული
              თარიღი მიუთითებს, როდის განახლდა ის ბოლოს.
            </p>
          </li>
          <li>
            <h2>სად შეიძლება მივიღო დამატებითი ინფორმაცია?</h2>
            <p>
              თუ თქვენ გაქვთ რაიმე შეკითხვა ჩვენს მიერ ქუქი-ფაილების ან სხვა
              ტექნოლოგიების გამოყენებასთან დაკავშირებით, გთხოვთ მოგვწეროთ ელ.
            </p>
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default Cookie;
